import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import loadingReducer from 'components/loading/loading.slice';
import authReducer, { initialAuthState } from 'modules/auth/auth.slice';
import brandReducer, {
	initialBrandState,
} from 'modules/brand/brand.slice';
import storeVersionReducer from 'modules/core/state/store-version/store-version.slice';
import themeReducer, {
	initialThemeState,
} from 'modules/core/theme/theme.slice';
import schemeReducer, {
	initialSchemeState,
} from 'modules/scheme/scheme.slice';
import walletReducer, {
	initialWalletState,
} from 'modules/wallet/wallet.slice';


// Define root reducer
const combinedReducers = combineReducers({
	auth: authReducer,
	brand: brandReducer,
	loading: loadingReducer,
	scheme: schemeReducer,
	storeVersion: storeVersionReducer,
	theme: themeReducer,
	wallet: walletReducer,
});

// Create and export a type for root reducer
export type RootState = ReturnType<typeof combinedReducers>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
	// Create current state varible
	let currentState = state;
	// if logout, set state to undefined
	action.type === 'auth/LOGOUT' && (currentState = undefined);
	// If new version, keep user logged in but reset other stores
	action.type === 'storeVersion/NEW_VERSION' &&
		(currentState = {
			...state,
			auth: initialAuthState,
			brand: initialBrandState,
			scheme: initialSchemeState,
			theme: initialThemeState,
			wallet: initialWalletState,
		});

	// If reset loading states, set events in progress to 0
	action.type === 'loading/RESET_LOADING_STATES' &&
		(currentState = {
			...state,
			auth: {
				...state.auth,
				eventsInProgress: 0,
			},
			brand: {
				...state.brand,
				eventsInProgress: 0,
			},
			loading: {
				...state.loading,
				eventsInProgress: 0,
			},
			scheme: {
				...state.scheme,
				eventsInProgress: 0,
			},
			wallet: {
				...state.wallet,
				eventsInProgress: 0,
			},
		});
	// return root reducer
	return combinedReducers(currentState, action);
};

export default rootReducer;
