import { rgba } from "polished";
import styled from "styled-components";
import ReactModal from "styled-react-modal";

import { IModalBackgroundProps } from "./modal.types";

import brand from "assets/styles/variables/brand";

export const modalBackground = styled.div<IModalBackgroundProps>`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(${({ viewHeight }) => viewHeight}px * 100%);
	background-color: ${({ colour }) => colour || rgba(brand.text, 0.5)};
	align-content: center;
	align-items: center;
	justify-content: center;
	z-index: 9;
`;

export const StyledModal = ReactModal.styled`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background: ${brand.white};
`;