import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IWalletState } from './wallet.types';

import { isAndroid, isIos } from 'helpers/device.helper';


export const initialWalletState: IWalletState = {
	eventsInProgress: 0,
};

const WalletSlice = createSlice({
	name: 'wallet',
	initialState: initialWalletState,
	reducers: {
		RESET_WALLET_STATE() {
			return { ...initialWalletState };
		},
		GET_WALLET_DETAILS(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_WALLET_DETAILS_SUCCESS(state, action) {
			// If non mobile device, remove loading event, otherwise keep loading whilst we load apple/google wallet
			let eventsInProgress = state.eventsInProgress;
			if(!isIos() && !isAndroid()) {
				eventsInProgress = eventsInProgress - 1;
			}

			return {
				...state,
				eventsInProgress,
				walletDetails: action.payload.data,
			};
		},
		GET_WALLET_DETAILS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_WALLET_STATE,
	GET_WALLET_DETAILS,
	GET_WALLET_DETAILS_SUCCESS,
	GET_WALLET_DETAILS_FAIL,
} = WalletSlice.actions;

/** Reset Wallet state */
export const resetWalletState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_WALLET_STATE());
};

export const getWalletDetails =
	(subDomain: string, userId: string, device?: 'apple' | 'android', key?: string | null) =>
		async (dispatch: Dispatch) => {
			// query params for request
			const params = key ? { params: { key } } : undefined;

			const response = await dispatch(
				GET_WALLET_DETAILS({
					request: {
						method: 'post',
						url: `/loyalty/loyalty-card/${subDomain}/${userId}`,
						headers: {
							'x-device-os': device || '',
						},
						...params
					},
				}),
			);

			return response.payload?.data;
		};

export default WalletSlice.reducer;
