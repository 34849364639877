import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global structure styles */
const globalStructure = `
html {
	font-family: ${fonts.family};
	font-size: ${fonts.sizes.base};

	body {
		margin: 0;
		line-height: ${fonts.lineHeight.base};
		font-weight: ${fonts.weights.default};
		font-size: ${fonts.sizes.standard};
		font-family: ${fonts.family};
		background: ${brand.white};
		color: ${brand.text};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		input, select, textarea, button {
			font-family: ${fonts.family};
		}
	}

	* {
		box-sizing: border-box;
	}
}
`;

export default globalStructure;
