import { motion } from "framer-motion";
import styled from "styled-components";

import { IIconComponentProps } from "./icon.types";

import { mq } from "assets/styles/variables/responsive";

export const StyledDiv = styled(motion.div)<IIconComponentProps>`
	height: ${({ height }) => height}px;
	width: ${({ width }) => width}px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	${({ heightMobile, widthMobile, height, width }) => mq.mobile`
		height: ${heightMobile || height}px;
		width: ${widthMobile || width}px;
	`}

	svg {
		color: ${({ colour }) => colour};
		width: 100%;
		height: 100%;
	}
`;