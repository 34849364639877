import { FunctionComponent, lazy, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import styled from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import store, { persistor } from './modules/core/state/store.config';

import brand from 'assets/styles/variables/brand';
import structure from 'assets/styles/variables/structure';
import { modalBackground } from 'components/modal/modal.styles';
import ErrorFallback from 'modules/core/error/error-fallback.component';

const AppRouter = lazy(
	() => import('./modules/core/routing/app-router.component'),
);
const Dialog = lazy(() => import('modules/core/dialog/dialog.component'));
const I18nProvider = lazy(
	() => import('./modules/core/i18n/i18n-provider.component'),
);
const Loading = lazy(() => import('components/loading/loading.component'));
const PusherComponent = lazy(
	() => import('modules/core/pusher/pusher.component'),
);
const StoreVersion = lazy(
	() => import('modules/core/state/store-version/store-version.component'),
);
const Theme = lazy(() => import('modules/core/theme/theme-provider.component'));

interface IStyledAppWrapperProps {
	viewHeight: number;
}

const StyledAppWrapper = styled.div<IStyledAppWrapperProps>`
	width: 100%;
	max-width: ${structure.container.maxWidth};
	height: calc(${({ viewHeight }) => viewHeight}px * 100%);
	margin: 0 auto;
	background: ${brand.white};
	position: relative;
`;

const App: FunctionComponent = () => {
	// variable to get and set view height
	const [viewHeight, setViewHeight] = useState<number>(
		window.innerHeight * 0.01,
	);

	// gets current view height and sets view height
	const getSetViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		setViewHeight(vh);
	};

	useEffect(() => {
		// Add event listener to resize
		window.addEventListener('resize', getSetViewHeight);

		// remove resize event listener
		return () => window.removeEventListener('resize', getSetViewHeight);
	}, []);

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<Suspense fallback={null}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<StoreVersion />
						<I18nProvider locale="en">
							<Theme>
								<ModalProvider backgroundComponent={modalBackground}>
									<Loading />
									<StyledAppWrapper viewHeight={viewHeight}>
										<AppRouter />
									</StyledAppWrapper>
									<PusherComponent />
								</ModalProvider>
								<Dialog />
							</Theme>
						</I18nProvider>
					</PersistGate>
				</Provider>
			</Suspense>
		</ErrorBoundary>
	);
};

export default App;
