import styled from "styled-components";

import { ILoadingContainerProps } from "./no-result.types";

import fonts from "assets/styles/variables/fonts";
import { mq } from "assets/styles/variables/responsive";
import Container from "components/container/container.component";
import Icon from "components/icon/icon.component";

export const StyledNoResults = styled(Container)`
	padding-top: 30px;
	padding-bottom: 30px;

	&.mod-full-height {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	&.mod-center {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	h1 {
		margin-bottom: 10px;
	}

	p {
		font-size: ${fonts.sizes.h4};
		margin: 0 3rem 1rem;
	}

	button {
		width: 100%;
		min-height: 50px;
		margin-top: 20px;

		${mq.tabletUp`min-height: 56px;`}
	}
`;

export const StyledIcon = styled(Icon)`
	margin: 0 auto 3rem;
`;

export const StyledLoadingContainer = styled.div<ILoadingContainerProps>`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	margin: 0 auto 3rem;
	color: ${({ theme }) => theme?.colors?.link};
`;

export const StyledBody = styled.div`
	margin: auto 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	
	@media screen and (min-height: 680px) {
		margin: 19vh 0 auto;
	}
`;