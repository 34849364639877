import { createSlice, Dispatch } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import {
	IUser,
	IAuthState,
	IJwt,
} from './auth.types';

import identifyUser from 'modules/core/logging/identify-user.helper';

// Create initial state
export const initialAuthState: IAuthState = {
	accessToken: {
		token: null,
		expiry: null,
	},
	eventsInProgress: 0,
	hasAuth: false,
	refreshToken: {
		token: null,
		expiry: null,
		refreshing: false,
	},
	user: {
		id: '',
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		RESET_AUTH_STATE() {
			return { ...initialAuthState };
		},
		REFRESH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: true,
				},
			};
		},
		REFRESH_SUCCESS(state, action) {
			// Decode access + refresh tokens
			const accessToken: IJwt = jwt_decode(action.payload?.data?.accessToken);
			const refreshToken: IJwt = jwt_decode(action.payload?.data?.refreshToken);

			return {
				...state,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: accessToken.exp,
					token: action.payload?.data?.accessToken,
				},
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
					expiry: refreshToken.exp,
					token: action.payload?.data?.refreshToken,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REFRESH_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
				},
			};
		},
		GET_PROFILE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PROFILE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PROFILE_SUCCESS(state, action) {
			return {
				...state,
				user: {
					...state.user,
					...action.payload.data,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ACCESS_TOKEN(state, action) {
			// Decode access token
			const accessToken: IJwt = jwt_decode(action.payload);

			return {
				...state,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: accessToken.exp,
					token: action.payload,
				},
			};
		},
		SET_PREVIOUS_URL(state, action) {
			return {
				...state,
				previousUrl: action.payload,
			};
		},
		SET_IS_WEB_ORDERING(state, action) {
			return {
				...state,
				isWebOrdering: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_AUTH_STATE,
	REFRESH,
	REFRESH_FAIL,
	REFRESH_SUCCESS,
	GET_PROFILE,
	GET_PROFILE_FAIL,
	GET_PROFILE_SUCCESS,
	SET_ACCESS_TOKEN,
	SET_PREVIOUS_URL,
	SET_IS_WEB_ORDERING,
} = authSlice.actions;

/** Reset state */
export const resetAuthState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_AUTH_STATE());
};

/** Refresh user's access token */
export const refreshAccessToken = (refreshToken: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		REFRESH({
			request: {
				method: 'post',
				url: 'auth/refresh',
				data: { refreshToken },
			},
		}),
	);

	return response.payload?.status === 200;
};

/** Get current user's profile */
export const getUserProfile = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_PROFILE({
			request: {
				method: 'get',
				url: 'me',
			},
		}),
	);

	// If request was successful
	if (response.payload?.status === 200 && response.payload.data) {
		const user: IUser = response.payload.data;
		identifyUser({
			id: user.id,
			name: user.firstName || '',
			email: user.email || '',
		});
	}

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** set user access token */
export const setAccessToken = (accessToken: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACCESS_TOKEN(accessToken));
};

/** set previous url */
export const setPreviousUrl = (previousUrl: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PREVIOUS_URL(previousUrl));
};

/** set is web ordering */
export const setIsWebOrdering = (isWebOrdering: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_IS_WEB_ORDERING(isWebOrdering));
};

export default authSlice.reducer;
