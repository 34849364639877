import { rgba } from "polished";
import styled from "styled-components";

import { IStyledLoadingComponentProps } from "./loading.types";

import brand from "assets/styles/variables/brand";
import fonts from "assets/styles/variables/fonts";

export const StyledLoadingComponent = styled.div<IStyledLoadingComponentProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 20;
	align-items: center;
	justify-content: center;
	transition: opacity 0.15s ease;
	background: ${rgba(brand.black, 0.6)};
	opacity: 0;
	pointer-events: none;

	${({ isLoading }) => isLoading && 'opacity: 1; pointer-events: all;'}
`;

export const StyledLoadingMessage = styled.div`
	font-size: ${fonts.sizes.h2};
	width: 100%;
	box-shadow: 4px 4px 8px ${rgba(brand.black, 0.1)};
	margin: 30px 0 0;
	color: ${({ theme }) => theme.colors.lblPrimary};
	background: ${({ theme }) => theme.colors.bgPrimary};
	border-radius: 15px;
	padding: 25px;
	text-align: center;
`;

export const StyledLoadingContainer = styled.div`
	width: 80px;
	height: 80px;
	position: relative;
	color: ${brand.white};
`;