import { FunctionComponent, ReactNode } from 'react';
import { use100vh } from 'react-div-100vh';
import { FormattedMessage } from 'react-intl';

import { StyledNoResults, StyledBody, StyledIcon } from './no-result.styles';
import { INoResultsButtonConfig } from './no-result.types';

import LoadingAnimation from 'components/animations/loading-animation.component';
import Button from 'components/button/button.component';
import { icons } from 'components/icon/icon.types';
import { StyledLoadingContainer } from 'components/loading/loading.styles';

interface IComponentProps {
	titleLocale: string;
	textLocale?: string;
	buttons?: INoResultsButtonConfig[];
	iconName?: keyof typeof icons;
	fullHeight?: boolean;
	center?: boolean;
	className?: string;
	showLoading?: boolean;
	content?: ReactNode;
	iconSize?: {
		width: number;
		height: number;
	};
	iconColor?: string;
}

/** Renders no results component */
const NoResults: FunctionComponent<IComponentProps> = ({
	titleLocale,
	textLocale,
	buttons,
	iconName,
	fullHeight,
	center,
	className,
	showLoading,
	content,
	iconSize = {
		width: 97,
		height: 97,
	},
	iconColor,
}) => {
	const screenHeight = use100vh();
	const height = fullHeight ? `${screenHeight}` : '';

	return (
		<StyledNoResults
			className={`${className} ${fullHeight ? 'mod-full-height' : ''} ${
				center ? 'mod-center' : ''
			}`}
			style={{
				height,
			}}
		>
			<StyledBody>
				{iconName && (
					<StyledIcon name={iconName} {...iconSize} colour={iconColor} />
				)}
				{showLoading && (
					<StyledLoadingContainer {...iconSize}>
						<LoadingAnimation />
					</StyledLoadingContainer>
				)}
				<h1>
					<FormattedMessage id={titleLocale} />
				</h1>
				{textLocale && (
					<p>
						<FormattedMessage id={textLocale} />
					</p>
				)}
				{content && <div>{content}</div>}
			</StyledBody>
			{buttons && buttons.length > 0
				? buttons.map((buttonConfig) => (
					<Button
						key={buttonConfig.locale}
						variant={buttonConfig.variant ?? 'primary'}
						type="button"
						onClick={() => buttonConfig.onClick()}
					>
						<FormattedMessage id={buttonConfig.locale} />
					</Button>
				  ))
				: null}
		</StyledNoResults>
	);
};

export default NoResults;
