import { MotionProps } from 'framer-motion';
import { FunctionComponent } from 'react';

import { StyledDiv } from './icon.styles';
import { icons } from './icon.types';

import brand from 'assets/styles/variables/brand';

// Interface for component props
interface IComponentProps extends MotionProps {
	name?: keyof typeof icons;
	colour?: string;
	height?: number;
	width?: number;
	className?: string;
	widthMobile?: number;
	heightMobile?: number;
}

const Icon: FunctionComponent<IComponentProps> = (props) => {
	// Destructure props
	const {
		name = 'logo',
		colour = brand.text,
		height = 30,
		width = 30,
		widthMobile,
		heightMobile,
		className,
	} = props;

	const IconContent = icons[name];
	return (
		<StyledDiv
			{...props}
			className={className}
			colour={colour}
			height={height}
			width={width}
			widthMobile={widthMobile}
			heightMobile={heightMobile}
			data-testid="svg-icon-div"
		>
			<IconContent />
		</StyledDiv>
	);
};

export default Icon;
