import { ReactElement, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { localeMessages } from './i18n.config';

import { TLocale } from 'app.types';

// Interface for component props
interface Ii18nProviderProps {
	locale: TLocale;
}

/** Higher order component for providing locale */
const I18nProvider = (
	props: PropsWithChildren<Ii18nProviderProps>,
): ReactElement => {
	const { locale } = props;

	return (
		<IntlProvider locale={locale} messages={localeMessages[locale]}>
			{props.children}
		</IntlProvider>
	);
};

export default I18nProvider;
