import { FunctionComponent } from 'react';

import { StyledSVG, StyledLine } from './loading-animation.styles';

const LoadingAnimation: FunctionComponent = () => (
	<StyledSVG fill="none" viewBox="0 0 46 46">
		<StyledLine
			className="line"
			stroke="currentColor"
			strokeWidth="3"
			d="M2.2 22.981a20.642 20.642 0 006.095 14.695 20.692 20.692 0 0014.709 6.086 20.694 20.694 0 0014.712-6.087c8.113-8.104 8.112-21.285 0-29.388A20.69 20.69 0 0023.004 2.2"
		/>
	</StyledSVG>
);
export default LoadingAnimation;
