import { FunctionComponent } from 'react';

import { StyledButton, StyledIcon } from './button.styles';
import { IButtonComponentProps } from './button.types';

const Button: FunctionComponent<
	IButtonComponentProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
	const { ariaLabel, icon, iconHeight, iconWidth, children, disabled } = props;

	const iconColour = !props.variant ? 'white' : props.iconColour;
	const size = !props.size ? 'lg' : props.size;
	const type = !props.type ? 'button' : props.type;
	const variant = !props.variant ? 'primary' : props.variant;

	return (
		<StyledButton
			aria-label={ariaLabel}
			variant={variant}
			type={type}
			size={size}
			whileHover={!disabled ? { scale: 1.01 } : ''}
			whileTap={!disabled ? { scale: 0.95 } : ''}
			{...props}
		>
			{icon && (
				<StyledIcon
					name={icon}
					width={iconWidth}
					height={iconHeight}
					colour={iconColour}
				/>
			)}
			{children}
		</StyledButton>
	);
};

export default Button;
