import styled from 'styled-components';

export const StyledSVG = styled.svg`
	transform: rotate(90deg);
	animation: spin 2s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const StyledLine = styled.path`
	animation: lineDraw 2s infinite;

	@keyframes lineDraw {
		0% {
			stroke-dashoffset: -98px;
			stroke-dasharray: 5px 98px;
		}
		10% {
			stroke-dashoffset: -98px;
			stroke-dasharray: 5px 98px;
		}
		55% {
			stroke-dashoffset: 0px;
			stroke-dasharray: 98px 98px;
		}
		100% {
			stroke-dashoffset: 0px;
			stroke-dasharray: 0px 98px;
		}
	}
`;

export const StyledDot = styled.path`
	transform-origin: 6px 6px;
	animation: dotDraw 2s infinite;

	@keyframes dotDraw {
		0% {
			transform: scale(1);
		}
		87.5% {
			transform: scale(1);
		}
		90% {
			transform: scale(0.75);
		}
		93% {
			transform: scale(1);
		}
		97% {
			transform: scale(0.75);
		}
		100% {
			transform: scale(1);
		}
	}
`;
