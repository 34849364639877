import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';

import { ILoyaltyScheme, ISchemeLogin, ISchemeState } from './scheme.types';

export const initialSchemeState: ISchemeState = {
	eventsInProgress: 0,
};

const SchemeSlice = createSlice({
	name: 'scheme',
	initialState: initialSchemeState,
	reducers: {
		RESET_SCHEME_STATE() {
			return { ...initialSchemeState };
		},
		LOGIN_TO_SCHEME(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		LOGIN_TO_SCHEME_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		LOGIN_TO_SCHEME_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_USER_SCHEME_DATA(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_USER_SCHEME_DATA_SUCCESS(state, action) {
			const { loyaltyScheme, ...userSchemeData } = action.payload.data;

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeScheme: loyaltyScheme,
				userSchemeData,
			};
		},
		GET_USER_SCHEME_DATA_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_SCHEME_REGISTRATION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_SCHEME_REGISTRATION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeRegistrationFields: action.payload.data,
			};
		},
		GET_SCHEME_REGISTRATION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_SCHEME_REGISTRATION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_SCHEME_REGISTRATION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_SCHEME_REGISTRATION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SEND_SCHEME_OTP(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		SEND_SCHEME_OTP_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SEND_SCHEME_OTP_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VERIFY_SCHEME_OTP(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		VERIFY_SCHEME_OTP_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		VERIFY_SCHEME_OTP_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ACTIVE_SCHEME(state, action) {
			return {
				...state,
				activeScheme: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_SCHEME_STATE,
	LOGIN_TO_SCHEME,
	LOGIN_TO_SCHEME_FAIL,
	LOGIN_TO_SCHEME_SUCCESS,
	GET_USER_SCHEME_DATA,
	GET_USER_SCHEME_DATA_FAIL,
	GET_USER_SCHEME_DATA_SUCCESS,
	GET_SCHEME_REGISTRATION,
	GET_SCHEME_REGISTRATION_FAIL,
	GET_SCHEME_REGISTRATION_SUCCESS,
	POST_SCHEME_REGISTRATION,
	POST_SCHEME_REGISTRATION_FAIL,
	POST_SCHEME_REGISTRATION_SUCCESS,
	SEND_SCHEME_OTP,
	SEND_SCHEME_OTP_FAIL,
	SEND_SCHEME_OTP_SUCCESS,
	VERIFY_SCHEME_OTP,
	VERIFY_SCHEME_OTP_FAIL,
	VERIFY_SCHEME_OTP_SUCCESS,
	SET_ACTIVE_SCHEME,
} = SchemeSlice.actions;

/** Reset state */
export const resetSchemeState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_SCHEME_STATE());
};

/** Login to a loyalty scheme */
export const loginToScheme =
	(schemeId: string, loginDetails: ISchemeLogin) =>
		async (dispatch: Dispatch) => {
			const response = await dispatch(
				LOGIN_TO_SCHEME({
					request: {
						method: 'post',
						url: `/loyalty/${schemeId}/login`,
						data: loginDetails,
					},
				}),
			);
			return response.payload?.data;
		};

/** Get user data for the scheme */
export const getUserSchemeData =
	(schemeId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_USER_SCHEME_DATA({
				request: {
					method: 'get',
					url: `/loyalty/${schemeId}`,
				},
			}),
		);

		return response.payload?.status === 200;
	};

/** Get scheme registration fields */
export const getSchemeRegistration =
	(schemeId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_SCHEME_REGISTRATION({
				request: {
					method: 'get',
					url: `/loyalty/${schemeId}/register`,
				},
			}),
		);

		return response.payload?.status === 200;
	};

/** post schema registration fields */
export const postSchemeRegistration =
	(schemeId: string, values: FormikValues) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			POST_SCHEME_REGISTRATION({
				request: {
					method: 'post',
					url: `/loyalty/${schemeId}/register`,
					data: values,
				},
			}),
		);

		return response.payload?.data;
	};

/** Send OTP code to a user */
export const sendSchemeOTP =
	(schemeId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			SEND_SCHEME_OTP({
				request: {
					method: 'get',
					url: `/loyalty/${schemeId}/verify`,
				},
			}),
		);

		return response.payload?.status === 200;
	};

/** verify OTP code for loyalty scheme */
export const verifySchemeLoginOTP =
	(schemeId: string, code: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			VERIFY_SCHEME_OTP({
				request: {
					method: 'post',
					url: `/loyalty/${schemeId}/verify`,
					data: { code },
				},
			}),
		);

		// Check if successful response
		const successResponse: number[] = [200, 201];
		return successResponse.includes(response.payload?.status);
	};

/** Set active scheme */
export const setActiveScheme =
	(schemeData: ILoyaltyScheme) => async (dispatch: Dispatch) => {
		await dispatch(SET_ACTIVE_SCHEME(schemeData));
	};
export default SchemeSlice.reducer;
