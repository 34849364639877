import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IBrandState } from './brand.types';

export const initialBrandState: IBrandState = {
	eventsInProgress: 0,
};

const BrandSlice = createSlice({
	name: 'brand',
	initialState: initialBrandState,
	reducers: {
		RESET_BRAND_STATE() {
			return { ...initialBrandState };
		},
		GET_BRAND_LOYALTY_SCHEMES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_LOYALTY_SCHEMES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeBrand: action.payload.data.brand,
				schemeList: action.payload.data.loyaltySchemes,
			};
		},
		GET_BRAND_LOYALTY_SCHEMES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_BRAND_STATE,
	GET_BRAND_LOYALTY_SCHEMES,
	GET_BRAND_LOYALTY_SCHEMES_FAIL,
	GET_BRAND_LOYALTY_SCHEMES_SUCCESS,
} = BrandSlice.actions;

/** Reset state */
export const resetBrandState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_BRAND_STATE());
};

/** Get brand loyalty schemes by brand ID */
export const getBrandLoyaltySchemes =
	(brandId: string) => async (dispatch: Dispatch) => {
		const response = await dispatch(
			GET_BRAND_LOYALTY_SCHEMES({
				request: {
					method: 'get',
					url: `/loyalty/brand/${brandId}`,
				},
			}),
		);

		return response.payload?.data;
	};

export default BrandSlice.reducer;
