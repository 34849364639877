import { createBrowserHistory } from 'history';
import { FunctionComponent, lazy } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

const InitialBrandLinkHandler = lazy(
	() =>
		import(
			'modules/brand/initial-brand-link-handler/initial-brand-link-handler'
		),
);

const NotFoundPage = lazy(() => import('./not-found.page'));

const BrandLandingPage = lazy(
	() => import('modules/brand/brand-landing/brand-landing.page'),
);

const SchemePage = lazy(() => import('modules/scheme/scheme.page'));

const SchemeLoginPage = lazy(
	() => import('modules/scheme/scheme-login/scheme-login.page'),
);

const SchemeOtpLoginPage = lazy(
	() => import('modules/scheme/scheme-login-otp/scheme-login-otp.page'),
);

const WalletPage = lazy(() => import('modules/wallet/wallet.page'));

const SchemeRegisterPage = lazy(
	() => import('modules/scheme/scheme-register/scheme-register.page'),
);

// Create and export browser history
export const history = createBrowserHistory();

/** Router component containing all application routes */
const AppRouter: FunctionComponent = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route
					exact
					path={['/brand/:brandId']}
					render={() => <InitialBrandLinkHandler />}
				/>
				<Route
					exact
					path={['/brand/:brandId/brand-list']}
					render={() => <BrandLandingPage />}
				/>
				<Route
					exact
					path={['/brand/:brandId/scheme/:schemeId/otp']}
					render={() => <SchemeOtpLoginPage />}
				/>
				<Route
					exact
					path={['/brand/:brandId/scheme/:schemeId/login']}
					render={() => <SchemeLoginPage />}
				/>
				<Route
					exact
					path={['/brand/:brandId/scheme/:schemeId/register']}
					render={() => <SchemeRegisterPage />}
				/>
				<Route
					exact
					path={[
						'/brand/:brandId/scheme/:schemeId',
						'/brand/:brandId/scheme/:schemeId/complete',
					]}
					render={() => <SchemePage />}
				/>
				<Route
					exact
					path={['/wallet', '/wallet/:brand']}
					render={() => <WalletPage />}
				/>
				<Route component={NotFoundPage} />
			</Switch>
		</BrowserRouter>
	);
};

export default AppRouter;
