// Get correct url for current environment
export const environmentUrl = (): string => {
	let environment = '';

	if (process.env.REACT_APP_ENVIRONMENT === 'production') {
		environment = 'https://order.orderpay.com';
	} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
		environment = 'https://order.staging.orderpay.com';
	} else if (process.env.REACT_APP_ENVIRONMENT === 'alpha') {
		environment = 'https://order.alpha.orderpay.com';
	} else if (process.env.REACT_APP_ENVIRONMENT === 'netlify') {
		environment = 'https://orderpay-onpl.netlify.app';
	} else {
		environment = 'https://localhost:3002';
	}

	return environment;
};

// Get the host name for the current environment
export const hostName = (subDomain: string = 'loyalty') => {
	switch (process.env.REACT_APP_ENVIRONMENT) {
	case 'production': {
		return `${subDomain}.orderpay.com`;
	}
	case 'staging': {
		return `${subDomain}.staging.orderpay.com`;
	}
	case 'alpha': {
		return `${subDomain}.alpha.orderpay.com`;
	}
	default: {
		return `${subDomain}.orderpay.com`;
	}
	}
};
