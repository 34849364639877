import MockAdapter from 'axios-mock-adapter/types';
import { FormikValues } from 'formik';

import {
	ILoyaltyScheme,
	IRegistrationField,
	ISchemeLogin,
	IUserSchemeData,
} from './scheme.types';

const userSchemeLogin: ISchemeLogin = {
	email: 'liam.canetti@checkfer.com',
	password: '4IAmTheBest20',
};
const loyaltyScheme: ILoyaltyScheme = {
	id: '80ba7b8a-72fb-4c32-9b6d-9998fcd48c8b',
	name: 'Liberation Loyalty Club',
	description:
		'Liberation Loyalty Club is our way of saying thank you to our loyal customers. The more you and your colleagues enjoy our Channel Islands pubs, bars and eateries, the more you’ll earn to spend with us again!',
};

const loyaltySchemes: ILoyaltyScheme[] = [
	loyaltyScheme,
	{
		id: 'fddf2bdd-4c56-429c-9be2-7d8a63f7ea0d',
		name: 'OrderPay Loyalty',
		description:
			'A new scheme for OrderPay customers. Enjoy a pint on us every once in a while!',
	},
];

const registrationFields: IRegistrationField[] = [
	{
		required: false,
		label: 'Title',
		options: [
			{
				label: 'Mr',
				value: 'Mr',
			},
			{
				label: 'Mrs',
				value: 'Mrs',
			},
			{
				label: 'Ms',
				value: 'Ms',
			},
			{
				label: 'Miss',
				value: 'Miss',
			},
			{
				label: 'Dr',
				value: 'Dr',
			},
			{
				label: 'Prof.',
				value: 'Prof',
			},
		],
		type: 'select',
		name: 'title',
		placeholder: 'Please select',
	},
	{
		min: 2,
		max: 64,
		required: true,
		label: 'First Name',
		type: 'text',
		name: 'first_name',
		placeholder: 'First Name',
	},
	{
		min: 2,
		max: 64,
		required: true,
		label: 'Last Name',
		type: 'text',
		name: 'last_name',
		placeholder: 'Last Name',
	},
	{
		min: 18,
		max: 100,
		required: false,
		label: 'Date of Birth',
		type: 'date',
		name: 'dob',
		placeholder: 'Enter Date of Birth',
	},
	{
		min: 5,
		max: 128,
		required: true,
		label: 'Email',
		type: 'email',
		name: 'email',
		placeholder: 'Email',
	},
	{
		min: 11,
		max: 14,
		required: false,
		label: 'Mobile Phone',
		type: 'telephone',
		name: 'mobile_phone',
		placeholder: 'Enter mobile number',
	},
	{
		min: 1,
		max: 128,
		required: false,
		label: 'Address Line 1',
		type: 'text',
		name: 'address1',
		placeholder: 'Address Line 1',
	},
	{
		min: 1,
		max: 128,
		required: false,
		label: 'Address Line 2',
		type: 'text',
		name: 'address2',
		placeholder: 'Address Line 2',
	},
	{
		min: 5,
		max: 10,
		required: false,
		label: 'Post Code',
		type: 'text',
		name: 'postcode',
		placeholder: 'Post Code',
	},
	{
		required: false,
		label:
			'Yes, I would like to receive emails about discounts, special offers, the loyalty club and events.',
		type: 'checkbox',
		name: 'opt_in_email',
	},
	{
		type: 'html',
		content:
			'<html><body>View our Terms and conditions <a href="https://www.liberationgroup.com/privacy">here</a>.</body></html>',
	},
	{
		required: true,
		label: 'Yes, I accept the terms of the Liberation Loyalty Club',
		type: 'checkbox',
		name: 'extra1',
	},
	{
		min: 6,
		max: 16,
		required: true,
		label: 'Password',
		type: 'password',
		name: 'password',
		placeholder: 'Password',
	},
];

const userSchemeRegisterFormValues: FormikValues = {
	address1: '3',
	address2: 'Milton Way',
	dob: '1994-04-11',
	email: 'john.wayne@yahoo.com',
	extra1: true,
	first_name: 'John',
	last_name: 'Wayne',
	mobile_phone: '01234 123 123',
	opt_in_email: true,
	password: 'hello123',
	postcode: 'mk404uu',
	title: 'Mr',
};

const userSchemeData: IUserSchemeData = {
	pointsBalance: 49332,
	qrCodeUrl: '42049330469',
	registrationNumber: '42049330469',
};

const postUserLogin = {
	request: userSchemeLogin,
};

const getUserSchemeData = {
	request: loyaltyScheme.id,
	response: {
		loyaltyScheme,
		...userSchemeData,
	},
};

const getRegisterLoyalty = {
	request: loyaltyScheme.id,
	response: registrationFields,
};

const postRegisterLoyalty = {
	request: {
		schemeId: loyaltyScheme.id,
		data: userSchemeRegisterFormValues,
	},
};

// const postRegisterLoyalty = {}

/** MOCKED ENDPOINTS */

// loyalty schemeid regex
export const userSchemeDataRegex = new RegExp(
	/loyalty\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/,
);
// loyalty login regex
export const userLoginToSchemeRegex = new RegExp(
	/loyalty\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/login/,
);

// loyalty register regex
export const loyaltyRegisterRegex = new RegExp(
	/loyalty\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/register/,
);

export const loyaltyVerifyRegex = new RegExp(
	/loyalty\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/verify/,
);

export const mockedSchemeEndpoints = (mockHttpClient: MockAdapter) => {
	// User login to loyalty scheme
	mockHttpClient.onPost(userLoginToSchemeRegex).reply(200);

	// Get loyalty scheme registration fields
	mockHttpClient
		.onGet(loyaltyRegisterRegex)
		.reply(200, getRegisterLoyalty.response);

	// Get user scheme data
	mockHttpClient
		.onGet(userSchemeDataRegex)
		.reply(200, getUserSchemeData.response);

	// post loyalty registration
	mockHttpClient.onPost(loyaltyRegisterRegex).reply(200);
};

const mockScheme = {
	data: {
		userSchemeLogin,
		loyaltyScheme,
		loyaltySchemes,
		registrationFields,
		userSchemeRegisterFormValues,
	},
	endpoints: {
		postUserLogin,
		getUserSchemeData,
		getRegisterLoyalty,
		postRegisterLoyalty,
	},
};
export default mockScheme;
