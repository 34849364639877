import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './app.component';
import { GlobalStyle } from './assets/styles/app';

import initialiseLogs from 'modules/core/logging/initialise-logs.helper';
import reportWebVitals from 'reportWebVitals';

/** Render react app */
ReactDOM.render(
	<StrictMode>
		<GlobalStyle />
		<App />
	</StrictMode>,
	document.getElementById('root'),
);

// Initialise logging tools
initialiseLogs();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
