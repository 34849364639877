import { AxiosError } from 'axios';

import { fireDialog } from '../../dialog/dialog.service';
import { intl } from '../../i18n/i18n.config';

import { userLoginToSchemeRegex } from 'modules/scheme/scheme.mock';

// Function to create errors on specific auth endpoints
export const authErrors = (error: AxiosError) => {
	if (
		error?.response?.config.url === 'auth/login' &&
		error.response.status === 401
	) {
		// Show login failed alert
		fireDialog({
			title: intl.formatMessage({
				id: 'errors.forms.login.failed.title',
			}),
			text: intl.formatMessage({
				id: 'errors.forms.login.failed.text',
			}),
		});
	}
};

export const schemeLoginErrors = (error: AxiosError) => {
	// If it's not the correct scheme login url
	if (
		!error?.response?.config.url ||
		!userLoginToSchemeRegex.test(error?.response?.config.url)
	) {
		return;
	}

	const errorEndpoints: number[] = [404, 401];

	if (errorEndpoints.includes(error.response.status)) {
		fireDialog({
			title: 'Error logging in',
			text: 'Please make sure your username and password are correct and try again.',
		});
	}
};
