import { motion } from "framer-motion";
import { rgba } from "polished";
import styled, { css } from "styled-components";

import { IButtonComponentProps } from "./button.types";

import brand from "assets/styles/variables/brand";
import fonts from "assets/styles/variables/fonts";
import Icon from "components/icon/icon.component";

const disabledStyling = css`
	opacity: 0.5 !important;
`;

const buttonWithIconStyling = css`
	display: flex;
	align-items: center;
`;

const primaryStyling = css`
	background: ${({ theme }) => theme.colors.link};
	color: ${({ theme }) => theme.colors.lblPrimary};
`;

const secondaryStyling = css`
	background: ${rgba(brand.primary, 0.3)};
	color: ${brand.primary};

	&:hover {
		background: ${rgba(brand.primary, 0.2)};
	}
`;

const ghostStyling = css`
	background: none;
	border: 1px solid ${brand.textLight};
	color: ${brand.text};
`;

const textStyling = css`
	background: none;
	border: none;
	color: ${brand.text};
`;

const iconStyling = css`
	background: none;

	&:hover {
		background: none;
	}
`;

// Create styled button component
export const StyledButton = styled(motion.button)<
	IButtonComponentProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
	line-height: 1;
	padding: ${({ size }) => (size === 'md' ? '12px' : '17px 27px')};
	border: none;
	border-radius: 10px;
	cursor: pointer;
	font-size: ${fonts.sizes.large};
	font-weight: ${fonts.weights.regular};
	text-align: center;
	transition: background 0.2s ease-out;
	outline: none;

	&:active {
		border-color: transparent;
		box-shadow: none;
	}

	${({ icon }) => {
		if (icon) {
			return buttonWithIconStyling;
		}
		return null;
	}}

	${({ variant }) => {
		if (variant === 'secondary') {
			return secondaryStyling;
		}
		if (variant === 'ghost') {
			return ghostStyling;
		}
		if (variant === 'icon') {
			return iconStyling;
		}
		if (variant === 'text') {
			return textStyling;
		}

		return primaryStyling;
	}}

${({ disabled }) => {
		if (disabled === true) {
			return disabledStyling;
		}
		return null;
	}}
`;

export const StyledIcon = styled(Icon)`
	margin-right: 10px;
`;
