import MockAdapter from 'axios-mock-adapter/types';

import { IBrand } from './brand.types';

import mockScheme from 'modules/scheme/scheme.mock';

const { loyaltySchemes } = mockScheme.data;

const brand: IBrand = {
	id: '14b4d139-df9c-440a-b0d7-cec5f77ea1a0',
	name: 'Liberation Group',
	logoUrl:
		'https://production-orderpay.s3.amazonaws.com/brand/images/logo/image/7e9db92f-be27-4d2c-a124-77251c8d11d6.png',
	actPrimary: '#FFFFFF',
	actSecondary: '#000000',
	bgPrimary: '#000000',
	bgSecondary: '#FFFFFF',
	lblPrimary: '#FFFFFF',
	lblSecondary: '#000000',
};


const getBrandLoyalty = {
	request: brand.id,
	response: {
		brand,
		loyaltySchemes
	},
};

/** MOCKED ENDPOINTS */

// get brand loyalty
export const brandLoyaltyRegex = new RegExp(/loyalty\/brand\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/);

export const mockedBrandEndpoints = (mockHttpClient: MockAdapter) => {
	// Get brand loyalty
	mockHttpClient.onGet(brandLoyaltyRegex).reply(200, getBrandLoyalty.response);
};

const mockBrand = {
	data: {
		brand,
		
	},
	endpoints: {
		getBrandLoyalty,
	},
};
export default mockBrand;
