import { HTMLMotionProps } from 'framer-motion';
import { FunctionComponent } from 'react';

import { StyledContainer } from './container.styles';

interface IComponentProps extends HTMLMotionProps<'div'> {
	id?: string;
	className?: string;
}
const Container: FunctionComponent<IComponentProps> = ({
	children,
	...props
}) => <StyledContainer {...props}>{children}</StyledContainer>;

export default Container;
