import { ReactComponent as checkbox } from 'assets/svg/checkbox.svg';
import { ReactComponent as chevron } from 'assets/svg/chevron.svg';
import { ReactComponent as error } from 'assets/svg/error.svg';
import { ReactComponent as fullLogo } from 'assets/svg/fullLogo.svg';
import { ReactComponent as logo } from 'assets/svg/logo.svg';
import { ReactComponent as select } from 'assets/svg/select.svg';
import { ReactComponent as successTick } from 'assets/svg/successTick.svg';

export const icons = {
	checkbox,
	chevron,
	error,
	fullLogo,
	logo,
	select,
	successTick,
};

export interface IIconComponentProps { 
	name?: keyof typeof icons;
	colour?: string;
	height?: number;
	width?: number;
	className?: string;
	widthMobile?: number;
	heightMobile?: number;
}